import { BorrowerNode, CallDetailsTab, IntentReasonMap, PhoneNumberNode } from 'src/typings/call.types';
import partition from 'lodash/partition';
import get from 'lodash/get';
import { CompanyNode } from 'src/typings/user.types';

export const getUserIntentReason = (intent: number, reason?: string) => {
  if (reason) return reason;
  if (intent === -1) return 'NA';
  return get(IntentReasonMap, intent, 'NA');
};

export const parseLatencyValues = (val: number) => {
  if (!val) return '-';
  return parseFloat(val.toString()).toFixed(2);
};

export const getXMinutesFromNow = (mins: number) => {
  const now = new Date();
  now.setMinutes(now.getMinutes() + mins);
  return now;
};

export type SupportedCurrency = 'rupee';

export const formatDuration = (val: string | number) => {
  if (!val || val === 'NA' || val === '-') return 'NA';
  return `${val}s`;
};

export const formatCurrency = (amount: string | number, currency?: SupportedCurrency): string => {
  switch (currency) {
    case 'rupee':
      return `₹${amount}`;
    default:
      return `₹${amount}`;
  }
};

export const getCompanyNameFromCompanyId = (id: CompanyNode['id'], companyList: CompanyNode[]) => {
  const wsCompany = companyList.find((i) => i.id === id);
  if (wsCompany) {
    return wsCompany.name;
  } else {
    return id;
  }
};

export const filterAllowedTabs = (tabs: CallDetailsTab[]): CallDetailsTab[] => tabs.filter((tab) => tab.isAllowed);

export const getCallSummary = (data: string) => {
  try {
    const res = JSON.parse(data);
    return {
      delay_reason: res.delay_reason,
      borrower_attitude: res.borrower_attitude,
    };
  } catch (error) {
    return {
      summary: data,
    };
  }
};

export const getBorrowerPaymentStatus = (borrower: BorrowerNode) => {
  if (borrower.amount_due === 0) {
    return 'PAID';
  } else {
    return 'NOT_PAID';
  }
};

export const intentDropdownOptions = Object.entries(IntentReasonMap).map((intent) => {
  return {
    id: intent[0],
    name: intent[1],
  };
});

type resultNode = {
  total: number;
  notSpam: number;
  spam: number;
};

export const preparePhoneNumberStats = (phoneNumbers: PhoneNumberNode[]): { [val: string]: resultNode } => {
  if (!phoneNumbers) return {};
  const total = partition(phoneNumbers, (p: PhoneNumberNode) => !p.spam);
  const telephony = partition(phoneNumbers, (p: PhoneNumberNode) => p.telephony === 'plivo');
  const plivo = partition(
    phoneNumbers.filter((i) => i.telephony === 'plivo'),
    (p: PhoneNumberNode) => !p.spam,
  );
  const exotel = partition(
    phoneNumbers.filter((i) => i.telephony === 'exotel'),
    (p: PhoneNumberNode) => !p.spam,
  );

  return {
    combined: {
      total: phoneNumbers.length,
      notSpam: total[0].length,
      spam: total[1].length,
    },
    plivo: {
      total: telephony[0].length,
      notSpam: plivo[0].length,
      spam: plivo[1].length,
    },
    exotel: {
      total: telephony[1].length,
      notSpam: exotel[0].length,
      spam: exotel[1].length,
    },
  };
};
