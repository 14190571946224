import { Dropdown } from '@components/Dropdown';
import { InformationCircleIcon, PencilAltIcon } from '@heroicons/react/outline';
import { XIcon } from '@heroicons/react/solid';
import { IconButton, Tooltip } from '@mui/material';
import { getUserIntentReason, intentDropdownOptions } from '@utils/call.utils';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';

type Props = {
  onChangeIntent: (newIntent: number) => {};
  currentIntent: number;
  generatedIntent: number;
};

export default function EditIntent({ currentIntent, generatedIntent, onChangeIntent }: Props) {
  const [editIntentMode, toggleEditIntentMode] = useState<boolean>(false);

  const handleChange = (val: any) => {
    onChangeIntent(val.id);
    toggleEditIntentMode(false);
  };

  const isSame = currentIntent === generatedIntent;

  return (
    <div className={twMerge('flex justify-between items-center gap-x-2 ', editIntentMode ? 'w-[300px]' : '')}>
      {editIntentMode ? (
        <div className="flex justify-start items-center gap-x-2">
          <Dropdown
            mandatory
            placeholder="Select intent"
            defaultValue={getUserIntentReason(currentIntent || -1)}
            options={intentDropdownOptions}
            onSelect={handleChange}
          />
          <IconButton onClick={() => toggleEditIntentMode(false)} title="Cancel">
            <XIcon className="w-5 h-5 text-red-500" />
          </IconButton>
        </div>
      ) : (
        <div className="flex justify-start items-center gap-x-2">
          {getUserIntentReason(currentIntent || -1)}
          {!isSame && (
            <Tooltip title={`Generated Intent: ${getUserIntentReason(generatedIntent)}`}>
              <InformationCircleIcon className="w-5 h-5 text-yellow-500" />
            </Tooltip>
          )}
        </div>
      )}

      {!editIntentMode && (
        <IconButton onClick={() => toggleEditIntentMode(true)} title="Edit">
          <PencilAltIcon className="w-5 h-5 text-primaryButton" />
        </IconButton>
      )}
    </div>
  );
}
