import { PrimaryButton, SecondaryButton } from '@components/Buttons';
import { Checkbox, IconButton } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useGetPhoneNumbers } from 'src/hooks/apis/phoneNumbers';
import { useUpdatePhoneNumber, useDeletePhoneNumber } from 'src/hooks/apis/phoneNumbers';
import { PhoneNumberNode } from 'src/typings/call.types';
import { twMerge } from 'tailwind-merge';
import AddPhone from './AddPhone';
import toast from 'react-hot-toast';
import { ClipboardCopyIcon, TrashIcon } from '@heroicons/react/outline';
import { SearchInput } from '@components/SearchInput';
import { TelephonyFilter } from '@components/TelephonyFilter';
import { SpamFilter } from '@components/SpamFilter';
import get from 'lodash/get';
import SpamStats from './SpamStats';

type Props = {};

export default function SpamManager({}: Props) {
  const iconButtonRef = useRef<HTMLDivElement>(null);
  const { data } = useGetPhoneNumbers();

  const { mutateAsync: deletePhone } = useDeletePhoneNumber();
  const { mutateAsync: updatePhone } = useUpdatePhoneNumber();
  const [filteredPhoneNumbers, setFilteredPhoneNumbers] = useState<PhoneNumberNode[]>(data);
  const [showAddNumberForm, toggleAddNumberForm] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<PhoneNumberNode | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filters, setFilters] = useState<object>({});

  useEffect(() => {
    if (data) {
      setFilteredPhoneNumbers([...data]);
    }
  }, [data]);

  const onSelectRow = (e, item: PhoneNumberNode) => {
    e.preventDefault();
    e.stopPropagation();
    if (selectedItem?.id === item.id) {
      setSelectedItem(null);
    } else {
      setSelectedItem(item);
    }
  };

  const onDelete = async () => {
    try {
      if (!selectedItem) return null;
      await deletePhone({ id: selectedItem.id });
      toast.success('Phone number deleted successfully');
      setSelectedItem(null);
    } catch (error) {}
  };

  const handleSearchChange = (val: string) => {
    setFilters((prevState) => ({
      ...prevState,
      phone_number: val,
    }));

    setSearchQuery(val);
  };

  const handleSpamChange = (filter: boolean | null) => {
    setFilters((prevState) => ({
      ...prevState,
      spam: filter,
    }));
  };

  const handleFilterChange = (filter: string | null) => {
    setFilters((prevState) => ({
      ...prevState,
      telephony: filter,
    }));
  };

  const handleFilterChanges = (data: PhoneNumberNode[], filters) => {
    let filtered = data || [];

    const search = get(filters, 'phone_number');
    const spam = get(filters, 'spam', null);
    const telephony = get(filters, 'telephony');
    if (search) {
      filtered = filtered?.filter((i) => {
        return i.phone_number.includes(search);
      });
    }

    if (spam !== null) {
      filtered = filtered?.filter((i) => {
        return i.spam === spam;
      });
    }

    if (telephony) {
      filtered = filtered?.filter((i) => {
        return i.telephony === telephony;
      });
    }

    setFilteredPhoneNumbers([...filtered]);
  };

  useEffect(() => {
    if (filters) {
      handleFilterChanges(data, filters);
    }
  }, [filters, data]);

  const onUpdate = async () => {
    try {
      if (!selectedItem) return null;
      await updatePhone({ id: selectedItem.id, payload: { spam: !selectedItem.spam } });
      toast.success('Phone number updated successfully');
      setSelectedItem(null);
    } catch (error) {}
  };

  const onCopyPhone = (phone: string) => {
    navigator.clipboard.writeText(phone);
    toast.success('Phone number copied!');
  };

  return (
    <div className="h-full w-full lg:w-1/2 flex flex-col justify-between lg:justify-center items-end gap-4">
      <div className="w-full flex justify-between items-center gap-x-2 h-11 min-h-[44px]">
        <div className="w-1/2">
          <SearchInput value={searchQuery} handleChange={handleSearchChange} placeholder="Search Phone numbers" />
        </div>

        <PrimaryButton
          onClick={() => toggleAddNumberForm(true)}
          propClass="text-xs h-8 lg:h-auto lg:text-md w-36 md:w-48 lg:w-48"
        >
          Add new Number
        </PrimaryButton>
      </div>

      <SpamStats phoneNumbers={filteredPhoneNumbers} />

      {showAddNumberForm && (
        <AddPhone onAdd={() => toggleAddNumberForm(false)} onClose={() => toggleAddNumberForm(false)} />
      )}
      <div className="w-full h-[calc(100%-88px)] overflow-auto p-2">
        <table className="bg-white border p-2 w-full mt-4 outline outline-1 outline-slate-300 rounded-lg overflow-hidden">
          <thead className="border p-2 bg-gray-200 w-full">
            <tr className="border p-2 w-full">
              <td className="border p-2 text-sm font-bold text-blue-950 w-7 max-w-7 lg:w-10 lg:max-w-10"></td>
              <td className="border p-2 text-sm font-bold text-blue-950">Phone Number</td>
              <td className="border p-2 text-sm font-bold text-blue-950">
                <div className="flex justify-between items-center relative">
                  <div ref={iconButtonRef}>Telephony</div>
                  <TelephonyFilter
                    onFilterChange={handleFilterChange}
                    prevFilter={null} //{get(prevParams, 'phone_numbers', null)}
                  />
                </div>
              </td>
              <td className="border p-2 text-sm font-bold text-blue-950 w-20">
                <div className="flex justify-between items-center relative">
                  <div ref={iconButtonRef}>Spam</div>
                  <SpamFilter
                    onFilterChange={handleSpamChange}
                    prevFilter={null} //{get(prevParams, 'phone_numbers', null)}
                  />
                </div>
              </td>
            </tr>
          </thead>
          <tbody>
            {filteredPhoneNumbers?.length === 0 ? (
              <tr className="w-full h-10 flex justify-center items-center font-medium text-sm">No data found</tr>
            ) : (
              filteredPhoneNumbers?.map((log) => {
                const isSelected = selectedItem?.id === log.id;
                return (
                  <tr
                    key={log.id}
                    className={twMerge(
                      'text-sm p-2 hover:bg-zinc-100 cursor-pointer transition-all ease-in-out',
                      isSelected ? 'bg-blue-100' : '',
                    )}
                  >
                    <td className="border px-1 lg:px-3 py-1">
                      <Checkbox checked={isSelected} onClick={(e) => onSelectRow(e, log)} />
                    </td>

                    <td className="border px-1 lg:px-3 py-1">
                      <div className="flex justify-between items-center gap-x-2">
                        <div>{log.phone_number}</div>
                        <IconButton title="Copy phone number" onClick={() => onCopyPhone(log.phone_number)}>
                          <ClipboardCopyIcon className="w-5 h-5" />
                        </IconButton>
                      </div>
                    </td>
                    <td className="border px-3 py-1">{log.telephony}</td>
                    <td className="border px-3 py-1 font-medium">{log.spam ? 'Yes' : 'No'}</td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <div className="w-full flex justify-end items-center gap-x-2 mt-4 h-11 min-h-[44px]">
        <SecondaryButton
          onClick={onDelete}
          disabled={selectedItem === null}
          propClass="text-xs lg:text-md w-20 md:w-32 gap-x-2"
        >
          <TrashIcon className="w-4 h-4 " />
          Delete
        </SecondaryButton>
        <PrimaryButton onClick={onUpdate} disabled={selectedItem === null} propClass="text-xs lg:text-md w-32 md:w-48">
          {selectedItem?.spam ? 'Mark as Not Spam' : 'Mark As Spam'}
        </PrimaryButton>
      </div>
    </div>
  );
}
