import { getBorrowerPaymentStatus } from '@utils/call.utils';
import { CallLogDetails } from 'src/typings/call.types';

type Props = {
  callDetails: CallLogDetails;
};

export default function PaymentStatusTag({ callDetails }: Props) {
  const status = getBorrowerPaymentStatus(callDetails.borrower);

  if (status === 'PAID') {
    return (
      <div className="flex justify-center items-center rounded-lg bg-green-700 text-white text-xs px-4 py-1 font-semibold">
        Paid
      </div>
    );
  }
  if (status === 'NOT_PAID') {
    return (
      <div className="flex justify-center items-center rounded-lg bg-red-700 text-white text-xs px-4 py-1 font-semibold">
        Not Paid
      </div>
    );
  }
  if (status === 'PARTIALLY_PAID') {
    return (
      <div className="flex justify-center items-center rounded-lg bg-yellow-700 text-white text-xs px-4 py-1 font-semibold">
        Partially Paid
      </div>
    );
  }
}
