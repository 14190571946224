import { PrimaryButton } from '@components/Buttons';
import { InputLabel, TextAreaField, TextField } from '@components/TextField';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import 'react-phone-number-input/style.css';
import { Dropdown } from '@components/Dropdown';
import { useState, useEffect, useContext } from 'react';
import { PhoneInput } from 'react-international-phone';
import DatePicker from 'react-datepicker';
import 'react-international-phone/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import { CoreAPIs } from 'src/api';
import { TopNavbar } from '@components/TopNavbar';
import { AuthContext } from 'src/contexts/auth';
import { AuthContextInterface } from 'src/contexts/auth/AuthContext';

export type IProps = {
  type?: 'admin';
};

enum Languages {
  English = 'en-IN',
  Hindi = 'hi-IN',
  Indonesian = 'id-ID',
}

const languageOptions = [
  { name: 'Hindi', id: Languages.Hindi },
  { name: 'English', id: Languages.English },
  { name: 'Indonesian', id: Languages.Indonesian },
];

const telephonyOptions = [
  { name: 'Plivo', id: 'plivo' },
  { name: 'Exotel', id: 'exotel' },
  { name: 'Random', id: 'random' },
];

const genderOptions = [
  { name: 'Male', id: 'male' },
  { name: 'Female', id: 'female' },
  { name: 'Other', id: 'other' },
];

const agentOptions = [
  { name: 'Knowl', id: 'knowl' },
  { name: 'Retell', id: 'retell' },
  { name: 'Bland', id: 'bland' },
];

type UserInfo = {
  amount_due: number;
  total_amount: number;
  due_date: Date;
};

type FormData = {
  fullName: string;
  user_info: UserInfo;
  gender: string;
  telephony: string;
  prompt?: string;
  penal_charges: number;
  offer_amount: number;
  offer_expiry: string | null;
};

export default function CollectionAgentForm() {
  const today = new Date();
  const startDate = new Date(today);
  startDate.setDate(today.getDate() - 14);
  const { isAdmin } = useContext(AuthContext) as AuthContextInterface;
  const [platform, selectPlatform] = useState<string | null>(isAdmin ? null : 'knowl');
  const [agent, selectAgent] = useState<string | null>(null);
  const [callingAgents, setCallingAgents] = useState<string[]>([]);
  const [telephony, selectTelephony] = useState<string | null>(isAdmin ? null : 'random');
  const [dueDate, setDueDate] = useState<Date>(startDate);
  const [offerExpiryDate, setOfferExpiryDateDate] = useState<Date | null>(null);
  const [language, selectLanguage] = useState<string | null>(Languages.Hindi);
  const [gender, selectGender] = useState<string>();
  const [phone, setPhone] = useState<string>('');
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid, errors },
    getValues,
  } = useForm<FormData>({
    defaultValues: {
      fullName: '',
      gender,
      prompt: '',
      penal_charges: 0,
      offer_expiry: null,
      offer_amount: 0,
      // telephony: isAdmin ? null : 'random',
      // platform: isAdmin ? null : 'knowl',
      user_info: {
        amount_due: 400,
        total_amount: 23000,
        due_date: new Date(),
      },
    },
    mode: 'onSubmit',
  });

  const handlePlatformChange = (val: any) => {
    if (val) {
      selectPlatform(val.id);
    }
  };

  const handleTelephonyChange = (val: any) => {
    if (val) {
      selectTelephony(val.id);
    }
  };

  const handleAgentChange = (val: any) => {
    if (val) {
      selectAgent(val.id);
    }
  };

  const handleLanguageChange = (val: any) => {
    if (val) {
      selectLanguage(val.id);
    }
  };
  const handleGenderChange = (val: any) => {
    if (val) {
      selectGender(val.id);
    }
  };

  const onSubmit = async () => {
    // const url = 'https://speech.knowl.io/api/call';
    const payload = {
      phone_number: phone,
      name: getValues().fullName,
      platform,
      agent,
      language,
      gender,
      prompt: getValues().prompt,
      telephony,
      offer_amount: getValues().offer_amount,
      penal_charges: getValues().penal_charges,
      offer_expiry: offerExpiryDate ? offerExpiryDate.toISOString().split('T')[0] : null,
      user_info: {
        ...getValues().user_info,
        due_date: dueDate.toISOString().split('T')[0],
      },
    };
    await CoreAPIs.makeCall(payload);

    toast.success('Call placed successfully.');
  };

  const getCallingAgents = async () => {
    const res = await CoreAPIs.getCallingAgents();
    setCallingAgents(res);
  };

  useEffect(() => {
    getCallingAgents();
    selectPlatform('knowl');
    selectAgent('random');
    selectTelephony('random');
  }, []);

  const callingAgentsDropdown = callingAgents.map((agent) => {
    return {
      name: agent,
      id: agent,
    };
  });

  callingAgentsDropdown.push({ name: 'Random', id: 'random' });

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen">
      <TopNavbar showBackButton />
      <div className="w-full h-[calc(100%-56px)] px-4 sm:px-10 md:px-20 py-4 md:py-10 overflow-y-auto max-h-screen bg-[#F8F8FA]">
        <div className="w-full h-screen flex flex-col justify-start items-center overflow-y-scroll">
          <div className="flex flex-col w-1/2 gap-y-10">
            <div className="text-4xl">Knowl AI Collection Agent Testing</div>
            <div className="flex flex-col gap-y-4 w-full h-full">
              <form className="w-full flex flex-col gap-y-5 " onSubmit={handleSubmit(onSubmit)}>
                <div className="w-full flex justify-between items-center gap-x-4">
                  <TextField
                    label="Name"
                    placeholder="John Doe"
                    mandatory
                    {...register('fullName', {
                      required: 'This field is mandatory',
                      setValueAs: (value: string) => value.trim(),
                    })}
                  />
                  <Dropdown
                    mandatory
                    placeholder="Select Gender"
                    options={genderOptions}
                    onSelect={handleGenderChange}
                    label="Gender"
                  />

                  <Dropdown
                    placeholder="Select Language"
                    options={languageOptions}
                    onSelect={handleLanguageChange}
                    label="Language"
                    defaultValue={'Hindi'}
                  />
                </div>

                <div className="flex flex-col gap-y-1">
                  <InputLabel label="Phone number" mandatory />
                  <PhoneInput
                    defaultCountry="in"
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    inputClassName="rounded-xl w-full h-10 min-h-10 focus:ring-0"
                    className="rounded-xl w-full h-10 min-h-10 "
                    countrySelectorStyleProps={{
                      className: 'h-10 min-h-10 rounded-xl w-12',
                    }}
                  />
                </div>
                <div className="flex justify-between items-center gap-x-4 w-full">
                  <TextField
                    label="Amount Due"
                    type="number"
                    placeholder="50000"
                    mandatory
                    {...register('user_info.amount_due', {
                      min: 0,
                      valueAsNumber: true,
                      required: 'This field is mandatory',
                    })}
                  />
                  <TextField
                    label="Loan Amount"
                    placeholder="50000"
                    type="number"
                    mandatory
                    {...register('user_info.total_amount', {
                      min: 0,
                      valueAsNumber: true,
                      required: 'This field is mandatory',
                    })}
                  />
                  <TextField
                    label="Penalty"
                    type="number"
                    placeholder="50000"
                    {...register('penal_charges', {
                      min: 0,
                      valueAsNumber: true,
                      required: 'This field is mandatory',
                    })}
                  />
                  <div className="flex flex-col">
                    <InputLabel label="Due Date" mandatory />
                    <DatePicker
                      selected={dueDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date) => setDueDate(date)}
                      className="h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                    />
                  </div>
                </div>
                {/* <div className="flex justify-between items-center gap-x-4 w-full">
                  <TextField
                    label="Offer Amount"
                    type="number"
                    placeholder="50000"
                    // mandatory
                    {...register('offer_amount', {
                      min: 0,
                      valueAsNumber: true,
                      // required: 'This field is mandatory',
                    })}
                  />
                  <div className="flex flex-col">
                    <InputLabel label="Offer Expiry" />
                    <DatePicker
                      selected={offerExpiryDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date: Date) => setOfferExpiryDateDate(date)}
                      className="h-[40px] caret-textPrimary text-textPrimary bg-inputBgColor inline-block border border-gray-300 focus:border-primaryButton font-small rounded-xl"
                    />
                  </div>
                </div> */}
                <div className="flex justify-between items-center gap-x-4 w-full">
                  {isAdmin && (
                    <div className="w-full">
                      <Dropdown
                        mandatory
                        placeholder="Select Agent"
                        options={callingAgentsDropdown}
                        onSelect={handleAgentChange}
                        label="Agent"
                        defaultValue={'Random'}
                      />
                    </div>
                  )}
                  {isAdmin && (
                    <div className="w-full">
                      <Dropdown
                        mandatory
                        placeholder="Select calling Platform"
                        options={agentOptions}
                        onSelect={handlePlatformChange}
                        label="Platform"
                        defaultValue={'Knowl'}
                      />
                    </div>
                  )}
                  {isAdmin && (
                    <div className="w-full">
                      <Dropdown
                        mandatory
                        placeholder="Select Telephony"
                        options={telephonyOptions}
                        onSelect={handleTelephonyChange}
                        label="Telephony Partner"
                        defaultValue="Random"
                      />
                    </div>
                  )}
                </div>

                {isAdmin && (
                  <div className="w-full">
                    <TextAreaField label="Prompt" placeholder="Enter the prompt" {...register('prompt', {})} />
                  </div>
                )}

                <div className="w-1/3 mt-4">
                  <PrimaryButton
                    type="submit"
                    disabled={!gender || !telephony || !platform || !agent || !phone || !isValid || isSubmitting}
                  >
                    Submit
                  </PrimaryButton>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
