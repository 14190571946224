import { preparePhoneNumberStats } from '@utils/call.utils';
import { useMemo } from 'react';
import { PhoneNumberNode } from 'src/typings/call.types';

type Props = {
  phoneNumbers: PhoneNumberNode[];
};

const StatNode = ({ title, active, spam, total }: { title: string; active: number; spam: number; total: number }) => {
  return (
    <div className="flex justify-start items-center gap-x-1 text-xs">
      <span className="font-medium text-primary">{title} -</span>
      <div className="text-green-800">
        Active: <span className="font-bold">{`${active}/${total}`}</span>
      </div>
      <div className="text-red-900">
        Spam: <span className="font-bold">{`${spam}/${total}`}</span>
      </div>{' '}
    </div>
  );
};

export default function SpamStats({ phoneNumbers }: Props) {
  if (!phoneNumbers) return null;

  const phoneNumberStats = useMemo(() => preparePhoneNumberStats(phoneNumbers), [phoneNumbers]);

  return (
    <div className="flex flex-col justify-start items-start gap-1 w-full">
      <div className="text-primary text-sm font-medium">Stats</div>
      <div className="flex flex-col justify-start items-start gap-1 w-full">
        <StatNode
          title="Total"
          active={phoneNumberStats?.combined?.notSpam}
          spam={phoneNumberStats.combined?.spam}
          total={phoneNumberStats.combined?.total}
        />

        <div className="flex justify-between items-center gap-x-2 w-full">
          <StatNode
            title="Plivo"
            active={phoneNumberStats?.plivo?.notSpam}
            spam={phoneNumberStats.plivo?.spam}
            total={phoneNumberStats.plivo?.total}
          />
          <StatNode
            title="Exotel"
            active={phoneNumberStats?.exotel?.notSpam}
            spam={phoneNumberStats.exotel?.spam}
            total={phoneNumberStats.exotel?.total}
          />
        </div>
      </div>
    </div>
  );
}
